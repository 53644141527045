/* eslint no-console: 0 */

import { createApp } from 'vue'
import PaymentForm from '../payment/form.js'

document.addEventListener('turbolinks:load', () => {
  let formDefined = document.getElementById("payment-form");
  if(formDefined){
    const app = createApp(PaymentForm);
    app.mount('#payment-form');
  }
})
