
import { computed, defineComponent, onMounted, reactive, toRefs } from 'vue';
import ModalTemplate from '../modal-template/template.vue'

export default defineComponent({
  components: {
    [ModalTemplate.name]: ModalTemplate,
  },
  setup() {
    const organization_address = $('#organization-address').data('address');

    const state = reactive({
      achAccountName: null,
      achAccountType: null,
      achToken: null,
      bridgeTerms: false,
      address1: organization_address?.street1 ?? "",
      city: organization_address?.city ?? "",
      state: organization_address?.state ?? "",
      zip: organization_address?.zip ?? "",
      poNumber: null,
      Notes:null,
      ccToken: null,
      ccExp: null,
      cvv: null,
      paymentType: 'credit',
      appliedCredit: 0,
      creditAmount: null,
      billing_address: true,
      extra_fee: 0
    })

    onMounted(() => {
      selectCredits();
      window.addEventListener('message', handleTokenizingResponse);
    });

    const disableForm = computed(() => {
      const termsFieldExists = document.querySelector('input#payment_terms_and_conditions');
      let element_invoice = document.getElementById('invoice_value');
      const invoice_indicator = element_invoice == null ? null : element_invoice.value;
      const baseDataInvalid = (!state.bridgeTerms && termsFieldExists) ||
                              !state.address1 ||
                              !state.city ||
                              !state.state ||
                              !(/(^\d{5}$)|(^\d{5}-\d{4}$)/).test(state.zip);
      const achDataInvalid = !state.achAccountName ||
                             !state.achToken ||
                             !state.achAccountType;

      const ccDataInvalid = !state.ccToken ||
                            !state.ccExp ||
                            !state.cvv;
                      

      if (state.paymentType == 'pay_by_invoice') {
        if(!state.bridgeTerms && termsFieldExists){
          return true;
        }else if(invoice_indicator == 0 || invoice_indicator == 1 ){
          $('#error_div').hide();
          return false;
        }else{
          $('#error_div').show();
          return true;
        }
      }else if(state.paymentType == 'pay_by_deposit'){
        return baseDataInvalid;
      }else if (state.paymentType == "accrued_credits" && creditsCoverTotalCost() && ((termsFieldExists && state.bridgeTerms) || !termsFieldExists) ) {
        return false;
      }else if (state.paymentType == 'credit') {
        return baseDataInvalid || ccDataInvalid;
      }else if(state.paymentType == 'ach'){
        return baseDataInvalid || achDataInvalid;
      }else{
        return true;
      }
    })

    const payingWithCredit = computed(() => {
      return state.paymentType == 'credit';
    })

    const payingWithACH = computed(() => {
      return state.paymentType == 'ach';
    })

    const payingWithDirectDeposit = computed(() => {
      return state.paymentType == 'pay_by_deposit' ? 1 : 0;
    })

    const payingWithInvoice = computed(() => {
      return state.paymentType == 'pay_by_invoice' ? 1 : 0;
    })

    const unableToAddCredit = () => {
      var totalCost = total_charges_with_fees();
      var credit_disabled = availableCredits() - state.creditAmount < 0 ||
                   state.creditAmount + totalPaid() + state.appliedCredit > totalCost ||
                   state.creditAmount > totalCost ||
                   !state.creditAmount;

      if (credit_disabled) return true;

      return state.creditAmount == null || Number(state.creditAmount) < 0 || isNaN(state.creditAmount);
    }
    
    const addCredit = () => {
      if (unableToAddCredit()) return true;
      state.appliedCredit += state.creditAmount;
      state.creditAmount = null;
      if (state.appliedCredit == total_charges_with_fees()){
        setClassesFromElement("credit_card", "btn disabled");
        setClassesFromElement("e_check", "btn disabled");
        setClassesFromElement("invoice", "btn disabled");
        setClassesFromElement("direct_deposit", "btn col-md-2 disabled");
        setNewPaymentMethod("accrued_credits");
      }
    }

    const total_charges_with_fees = () => {
      return Number($("#total_value").attr("value_bind"));
    }

    const setClassesFromElement = (idElement, classes) => {
      let eCheckButton = document.getElementById(idElement);
      if(eCheckButton){
        eCheckButton.className = classes;
      }
    }

    const deleteCredit = () => {
      state.appliedCredit = 0;
      setClassesFromElement("credit_card", "btn");
      setClassesFromElement("e_check", "btn");
      setClassesFromElement("invoice", "btn");
      setClassesFromElement("direct_deposit", "btn col-md-2");
      setNewPaymentMethod(null);
    }

    const availableCredits = () => {
      var currentCredits = $("#total-credits").data('creditsAvailable');
      return (currentCredits - state.appliedCredit);
    }

    const creditsCoverTotalCost = () => {
      var totalCost = total_charges_with_fees();
      return (state.appliedCredit >= totalCost);
    }

    const setNewPaymentMethod = (newMethod) => {
      state.paymentType = newMethod;
    }

    const calculateTotalWithCreditCardFee = () => {
      return calculateTotalWithoutCreditCardFee() + calculateCreditCardFee();
    }
    
    const calculateTotalWithoutCreditCardFee = () => {
      return total_charges_with_fees() - totalPaid() - state.appliedCredit;
    }

    const totalPaid = () => {
      let creditsAppliedBefore = Number($("#credits_applied_before").attr("value_bind"));
      let cashPayment = Number($("#paid_amount").attr("value_bind"));
      return creditsAppliedBefore + cashPayment;
    }

    const calculateCreditCardFee = () => {
      let element = $("#credit_card_percentage_fee");
      let creditCardPercentageFee = element == null ? 0 : Number(element.attr("value_bind"));
      state.extra_fee = calculateTotalWithoutCreditCardFee() * creditCardPercentageFee;
      return state.extra_fee;
    }

    const selectCreditCard = () => {
      setNewPaymentMethod('credit');    
      state.billing_address = true
    }

    const selectACHAccount = () => {
      setNewPaymentMethod('ach');
      state.billing_address = true
    }

    const selectDirectDeposit = () => {
      setNewPaymentMethod("pay_by_deposit");
      state.billing_address = true
    }    

    const selectCredits = () => {
      let newType = creditsCoverTotalCost() ? "accrued_credits" : null;
      setNewPaymentMethod(newType);
      state.billing_address = false;
    }

    const selectInvoice = () => {      
      setNewPaymentMethod("pay_by_invoice");
      state.billing_address = false;
    }

    const check_submit_button_enable = () => {
      const termsFieldExists = document.querySelector('input#payment_terms_and_conditions');
      const baseDataInvalid = (!state.bridgeTerms && termsFieldExists) ||
                               !state.address1 ||
                               !state.city ||
                               !state ||
                               !(/(^\d{5}$)|(^\d{5}-\d{4}$)/).test(state.zip);
      const achDataInvalid = !state.achAccountName ||
                             !state.achToken ||
                             !state.achAccountType;

      const ccDataInvalid = !state.ccToken ||
                            !state.ccExp ||
                            !state.cvv;

      if (state.paymentType == null && creditsCoverTotalCost() && ((termsFieldExists && state.bridgeTerms) || !termsFieldExists)){
        return false;
      }else if (state.paymentType == 'credit') {
        return baseDataInvalid || ccDataInvalid;
      }else if(state.paymentType == 'ach'){
        return baseDataInvalid || achDataInvalid;
      }else{
        return true;
      }
    }



    const handleTokenizingResponse = (event) => {
      // Next line doesn't allow us to stub this message out in the user tests
      // if (event.origin !== "https://fts.cardconnect.com") return;
      try {
          var payload = JSON.parse(event.data);
          if (payload.hasOwnProperty('ccToken')) {
            console.log('Received Token (cc)');
            state.ccToken = payload.ccToken;
            $('#credit_card_token').value = state.ccToken;
          } else if (payload.hasOwnProperty('achToken')) {
            console.log('Received Token (ach)');
            state.achToken = payload.achToken;
            $('#ach_token').value = state.achToken;
          }
      } catch (e) {
        return false;
      }
    }

    return { 
      ...toRefs(state), 
      disableForm, 
      payingWithCredit, 
      payingWithACH, 
      payingWithDirectDeposit,
      payingWithInvoice,
      unableToAddCredit,
      addCredit,
      deleteCredit,
      availableCredits,
      creditsCoverTotalCost,
      setNewPaymentMethod,
      selectCreditCard,
      calculateTotalWithCreditCardFee,
      calculateTotalWithoutCreditCardFee,
      totalPaid,
      calculateCreditCardFee,
      selectACHAccount,
      selectDirectDeposit,
      selectCredits,
      selectInvoice,
      check_submit_button_enable,
      handleTokenizingResponse
    }
  },
  beforeDestroy() {
    window.removeEventListener('message', handleTokenizingResponse);
  },  
});
